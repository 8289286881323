body {
  font-family: "Open Sans", sans-serif;
  background-color: #444;
  margin: 0 auto;
  font-size: 10pt;
  --light-blue: #ADD8E6;
  --med-blue: #4C4CAD;
  --dark-blue: #353579;
  --pixel-font: "Silkscreen", monospace;
}
#content {
  max-width: 8.5in;
  min-height: 11in;
  margin: auto;
  margin-bottom: 1em;
  margin-top: 1em;
  background-color: white;
  padding: 0.5em 2em;
  position: relative;
  box-sizing: border-box;
}
#title {
  text-align: center;
}
h1, h2 {
  font-family: var(--pixel-font);
}
h1, h2 {
  margin: 0;
}
h1 {
  font-size: 20pt;
}
h2 {
  font-size: 16pt;
}
a {
  color: black;
}

#blurb {
  display: flex;
  flex-direction: column;
  font-size: 12pt;
  margin: 0;
}
#blurb span {
  white-space: nowrap;
}

#contact {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
  justify-content: space-around;
  padding-top: 0.5em;
}

#columns {
  display: flex;
  margin-top: 0.5em;
}

#columns .column:first-of-type {
  max-width: 30%;
  display: block;
  padding-right: 1.5em;
}
#columns .column:last-of-type {
  flex-grow: 1;
  display: block;
}

.title {
  font-family: var(--pixel-font);
  font-size: 11pt;
  width: 100%;
  border-bottom: 2px solid;
}
.title, .info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.sub.title {
  font-size: 9pt;
  color: #666;
  padding-top: 0.5em;
}
.main.title {
  font-size: 13pt;
}
.title span:first-of-type {
  width: 45%;
}

.skill-list {
  display: flex;
  gap: 0 0.5em;
  flex-wrap: wrap;
}
.skill-list div:not(:last-of-type):after {
  content: ","
}

ul {
  padding-left: 1em;
  margin: 0;
}

.section {
  margin-top: 1em;
}

.selectable {
  cursor: pointer;
}

.selected {
  background-color: var(--med-blue);
  color: white;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
.selected .sub.title {
  color: #CCC;
}

/* only highlight if we can hover, otherwise just select */
@media (hover: hover) {
  /* highlight only leaf .selectable */
  .selectable:hover:not(:has(.selectable:hover)),
  .selectable.highlighted:not(.selected) {
    background-color: var(--light-blue);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
  }
  .selected:hover:not(:has(.selectable:hover)),
  .selected .selectable:hover:not(:has(.selectable:hover)),
  .selected.highlighted,
  .selected .selectable.highlighted {
    background-color: var(--dark-blue);
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
  }
}

li:empty {
  display: none;
}
li p {
  margin: 0;
}

#source {
  font-family: var(--pixel-font);
  display: block;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 12pt;
  margin-bottom: 1em;
}

#controls,
#control-spacer {
  width: 100%;
  font-family: var(--pixel-font);
  font-weight: bold;
  background-color: white;
  border-top: 2px solid #333;
  padding: 1em 3em;
  display: flex;
  justify-content: flex-end;
  gap: 2em;
  box-sizing: border-box;
  flex-wrap: wrap;
}
#controls {
  position: fixed;
  bottom:0;
}
#control-spacer {
  visibility: hidden;
}

#hide-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

#controls button {
  font-family: var(--pixel-font);
  background-color: var(--med-blue);
  white-space: nowrap;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  padding-top: 3px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}
#controls button:disabled {
  background-color: #888;
  color: #CCC;
  cursor: default;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

@media print {
  .nobreak {
    break-inside: avoid;
  }
  #footer {
    display: none;
  }
  #content {
    margin: 0;
  }
}
@page {
  size: 8.5in 11in;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  #columns {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 8pt;
  }
  .title {
    font-size: 10pt;
  }
  .main.title {
    font-size: 12pt;
  }
  .sub.title {
    font-size: 8pt;
  }
  #columns .column:first-of-type {
    width: auto;
    max-width: 100%;
  }
}
